import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Spinner from '../../../../sea-site-theme/src/components/utils/spinner'
import { useQuery, gql } from '@apollo/client'
import { Client } from '../../../../sea-site-theme/src/apollo/client'
// import AddToCart from './add-to-cart'
import isEmpty from 'lodash.isempty'
import { onProductClick } from '../../../../sea-site-theme/src/components/utils/analytics'
import { navigate } from 'gatsby'
import { ProductImage } from '../../../../sea-site-theme/src/templates/product-lines'
import {
	MainSection,
	ProductsContainer,
	Main,
	ProductsInnerContainer,
	ItemDetails,
	Continue,
	NullText,
	MainItemContainer,
	ItemContainer,
	ItemInnerContainer,
	ItemImageContainer,
	ItemImage,
	DetailsContainer,
	ProductName,
	AddToCartContainer,
	Price,
	Feature,
	SubItemContainer,
	SubItemsContainer,
	SubItemsInnerContainer,
	SubItemsDetails,
	SubItemBtns,
	SubItemImageContainer,
	SubItemAddToCartContainer,
	SubItemDetails,
	TrialRequest,
	Request,
	SubTrialRequest,
	EnquireNow,
	Enquire,
	SubEnquire,
	MainDetailsContainer,
	MainProductName,
	MainFeature,
} from './styles'

interface Items {
	active: boolean
	brand: string
	categories: {
		title: string
		slug: string
	}
	featuresCollection: {
		items: {
			title: string
		}[]
	}
	imagesCollection: {
		items: {
			description: string
			url: string
		}[]
	}
	model: string
	price: number
	seoDescription: string
	seoTitle: string
	shippingClass: string
	sku: string
	slug: string
}
interface Products {
	items: Items[]
}

interface Prop {
	productImage: {
		edges: {
			node: ProductImage
		}[]
	}
	prodSeries: string
}

const Products = ({ productImage, prodSeries }: Prop): JSX.Element => {
	const filterAllByLocale = (items: { node: ProductImage }[]) => {
		return items
			.filter((item) => {
				return item.node.series === prodSeries
			})
			.filter((item) => {
				return item.node.categories === null ? item : item.node.categories?.slug != 'spare-parts'
			})
	}

	const isBrowser = typeof window !== 'undefined'
	const [items, setItems] = useState<Items[] | null>(null)

	useEffect(() => {
		/**
		 * Google Analytics - Tag Manager - Enhanced Ecommerce
		 * Measuring product impressions for every search result change
		 * */
		const floatPrice = (price: number) =>
			price >= 1
				? typeof price != 'number'
					? parseFloat(price).toFixed(2)
					: price.toFixed(2)
				: parseFloat('0').toFixed(2)
		if (items) {
			const impressions = items.map((item, index) => ({
				name: item.seoTitle, // the name of the product, including Title and Subtitle
				id: item.sku, // product serial number
				price: Number(floatPrice(item.price)), // actual price per unit or in case of fixed price discounts the discounted price
				brand: item.brand, // product brand
				category: [item.categories && item.categories.title].toString(), // Dolphin domestic, Dolphin commercial, mineral swim, ozone swim
				list: 'Category List', // the type of list for the current product impression )e.g. Category List, Search Results etc.)
				position: index + 1, // the position of the product within the list
			}))
			if (isBrowser && window.dataLayer && items.length >= 1) {
				window.dataLayer.push({
					event: 'productImpression',
					ecommerce: {
						currencyCode: 'SGD',
						impressions,
					},
				})
			}
		}
	}, [items])

	const [products, setProducts] = useState<Products | null>(null)
	const [overrideItems, setOverrideItems] = useState<Items[] | null>(null)
	const { loading, error, data } = useQuery(APOLLO_PRODUCT_LINE_QUERY, {
		variables: { series: prodSeries },
		client: Client,
	})

	useEffect(() => {
		if (data) {
			setProducts(data.productCollection)
		}
	}, [data])

	useEffect(() => {
		setItems(() => {
			return products !== null ? products.items : []
		})
		const overrideItems =
			products != null
				? products.items
						?.map((item) => {
							return { ...item, val: 1 }
						})
						?.filter((item) => {
							return item.categories === null ? item : item.categories.slug != 'spare-parts'
						})
				: []
		setOverrideItems(overrideItems)
	}, [products])
	const filteredImages = filterAllByLocale(productImage.edges)
	const [firstItem, ...rest] = overrideItems !== null ? overrideItems : []
	const mainItems = [firstItem]
	const otherItems = [...rest]
	const remainder = [...rest].length % 3
	return (
		<MainSection>
			<ProductsContainer>
				{loading ? (
					<Spinner />
				) : (
					<Main>
						{overrideItems !== null && overrideItems.length > 0 ? (
							overrideItems.length >= 4 ? (
								<ProductsInnerContainer>
									<MainItemContainer>
										{products !== null ? (
											products.items.length > 0 ? (
												mainItems &&
												mainItems.map((item: Items, i: number) => {
													if (!isEmpty(item)) {
														const title = item.seoTitle
														const titleSplit = title.split(' ')
														const productBrand = titleSplit.find(
															(ttl) => ttl === 'Dolphin' || ttl === 'Mineral' || ttl === 'Ozone'
														)
														const pBrand =
															productBrand === 'Dolphin'
																? productBrand
																: productBrand === 'Mineral'
																? 'Mineral Swim'
																: productBrand === 'Ozone'
																? 'Ozone Swim'
																: ''

														const itemName = item.seoTitle.split(pBrand).slice(1)

														const filterImg = (img: { node: ProductImage }[]) => {
															return img.filter((x) => {
																return x.node.slug === item.slug ? x : ''
															})
														}
														const filteredByslug = filterImg(filteredImages)
														return (
															<ItemContainer key={i}>
																<ItemInnerContainer>
																	<ItemImageContainer
																		onClick={() => {
																			onProductClick(
																				{
																					...item,
																					name: item.seoTitle,
																					list: 'Category List', // the type of list for the current product impression )e.g. Category List, Search Results etc.)
																					position: i + 1, // the position of the product within the list
																				},
																				'Category List',
																				i,
																				navigate(`/product/${item.slug}`)
																			)
																		}}
																	>
																		<ItemImage>
																			<source
																				srcSet={filteredByslug[0].node.images[0].fluid.srcWebp}
																				type="image/webp"
																			/>
																			<source
																				srcSet={`${
																					filteredByslug[0].node.images[0].fluid.src.split('?')[0]
																				}?fm=png&fl=png8&fit=pad&w=600&h=400`}
																			/>
																			<img
																				src={filteredByslug[0].node.images[0].fluid.src}
																				alt={filteredByslug[0].node.images[0].description}
																			/>
																		</ItemImage>
																	</ItemImageContainer>
																	<MainDetailsContainer>
																		<DetailsContainer>
																			<MainProductName>
																				<span>{pBrand}</span>
																				{itemName}
																			</MainProductName>
																			{item.featuresCollection.items.map((feature, i) => {
																				return <MainFeature key={`${feature.title}${i}`}>{feature.title}</MainFeature>
																			})}
																		</DetailsContainer>
																		{item.active === null || item.active === true ? (
																			prodSeries != 'Wave' ? (
																				// <AddToCartContainer>
																				// 	<Price>
																				// 		{item.price.toLocaleString('th-TH', {
																				// 			style: 'currency',
																				// 			currency: 'SGD',
																				// 		})}
																				// 	</Price>
																				// 	<AddToCart items={item} index={i} />
																				// </AddToCartContainer>
																				<EnquireNow
																					to={`/contact-us/?subject=${encodeURIComponent(
																						item.seoTitle.toLowerCase()
																					)}#contact-form`}
																				>
																					<Enquire>Enquire now</Enquire>
																				</EnquireNow>
																			) : (
																				<TrialRequest
																					to={`/contact-us/?subject=commercial+trial+${encodeURIComponent(
																						item.model.toLowerCase()
																					)}#contact-form`}
																				>
																					<Request>Request a Trial</Request>
																				</TrialRequest>
																			)
																		) : prodSeries != 'Wave' ? (
																			<EnquireNow
																				to={`/contact-us/?subject=${encodeURIComponent(
																					item.seoTitle.toLowerCase()
																				)}#contact-form`}
																			>
																				<Enquire>Enquire now</Enquire>
																			</EnquireNow>
																		) : (
																			<EnquireNow
																				to={`/contact-us/?subject=commercial+trial+${encodeURIComponent(
																					item.model.toLowerCase()
																				)}#contact-form`}
																			>
																				<Enquire>Request a Trial</Enquire>
																			</EnquireNow>
																		)}
																		<ItemDetails
																			onClick={() => {
																				onProductClick(
																					{
																						...item,
																						name: item.seoTitle,
																						list: 'Category List', // the type of list for the current product impression )e.g. Category List, Search Results etc.)
																						position: i + 1, // the position of the product within the list
																					},
																					'Category List',
																					i,
																					navigate(`/product/${item.slug}`)
																				)
																			}}
																		>
																			<Continue>View Product</Continue>
																		</ItemDetails>
																	</MainDetailsContainer>
																</ItemInnerContainer>
															</ItemContainer>
														)
													}
												})
											) : (
												<NullText>No items can be found!</NullText>
											)
										) : (
											[]
										)}
									</MainItemContainer>
									{otherItems.length > 0 ? (
										<SubItemContainer>
											{products !== null && products.items.length > 0
												? otherItems.map((item, i) => {
														if (!isEmpty(item)) {
															const title = item.seoTitle
															const titleSplit = title.split(' ')
															const productBrand = titleSplit.find(
																(ttl) => ttl === 'Dolphin' || ttl === 'Mineral' || ttl === 'Ozone'
															)
															const pBrand =
																productBrand === 'Dolphin'
																	? productBrand
																	: productBrand === 'Mineral'
																	? 'Mineral Swim'
																	: productBrand === 'Ozone'
																	? 'Ozone Swim'
																	: ''

															const itemName = item.seoTitle.split(pBrand).slice(1)

															const filterImg = (img: { node: ProductImage }[]) => {
																return img.filter((x) => {
																	return x.node.slug === item.slug ? x : ''
																})
															}

															const filteredByslug = filterImg(filteredImages)
															return (
																<SubItemsContainer key={i} wid={remainder === 1}>
																	<SubItemsInnerContainer className={otherItems.length > 1 ? '' : 'notAuto'}>
																		<SubItemsDetails className={otherItems.length > 1 ? '' : 'notAuto'}>
																			<SubItemImageContainer
																				onClick={() => {
																					onProductClick(
																						{
																							...item,
																							name: item.seoTitle,
																							list: 'Category List', // the type of list for the current product impression )e.g. Category List, Search Results etc.)
																							position: i + 1 + mainItems.length, // the position of the product within the list
																						},
																						'Category List',
																						i + mainItems.length,
																						navigate(`/product/${item.slug}`)
																					)
																				}}
																			>
																				<ItemImage>
																					<source
																						srcSet={filteredByslug[0].node.images[0].fluid.srcWebp}
																						type="image/webp"
																					/>
																					<source
																						srcSet={`${
																							filteredByslug[0].node.images[0].fluid.src.split('?')[0]
																						}?fm=png&fl=png8&fit=pad&w=600&h=400`}
																					/>
																					<img
																						src={filteredByslug[0].node.images[0].fluid.src}
																						alt={filteredByslug[0].node.images[0].description}
																					/>
																				</ItemImage>
																			</SubItemImageContainer>
																			<DetailsContainer className={otherItems.length > 1 ? '' : 'notAuto'}>
																				<ProductName>
																					<span>{pBrand}</span>
																					{itemName}
																				</ProductName>
																				{item.featuresCollection.items.map((feature, i) => {
																					return <Feature key={`${feature.title}${i}`}>{feature.title}</Feature>
																				})}
																			</DetailsContainer>
																			<SubItemBtns className={otherItems.length > 1 ? '' : 'notAuto'}>
																				{item.active === null || item.active === true ? (
																					prodSeries != 'Wave' ? (
																						// <SubItemAddToCartContainer>
																						// 	<Price>
																						// 		{item.price.toLocaleString('th-TH', {
																						// 			style: 'currency',
																						// 			currency: 'SGD',
																						// 		})}
																						// 	</Price>
																						// 	<AddToCart items={item} index={i} />
																						// </SubItemAddToCartContainer>
																						<SubEnquire
																							to={`/contact-us/?subject=${encodeURIComponent(
																								item.seoTitle.toLowerCase()
																							)}#contact-form`}
																						>
																							<Enquire>Enquire now</Enquire>
																						</SubEnquire>
																					) : (
																						<SubTrialRequest
																							to={`/contact-us/?subject=commercial+trial+${item.model}#contact-form`}
																						>
																							<Request>Request a Trial</Request>
																						</SubTrialRequest>
																					)
																				) : prodSeries != 'Wave' ? (
																					<SubEnquire
																						to={`/contact-us/?subject=${encodeURIComponent(
																							item.seoTitle.toLowerCase()
																						)}#contact-form`}
																					>
																						<Enquire>Enquire now</Enquire>
																					</SubEnquire>
																				) : (
																					<SubEnquire
																						to={`/contact-us/?subject=commercial+trial+${encodeURIComponent(
																							item.model.toLowerCase()
																						)}#contact-form`}
																					>
																						<Enquire>Request a Trial</Enquire>
																					</SubEnquire>
																				)}
																				<SubItemDetails
																					onClick={() => {
																						onProductClick(
																							{
																								...item,
																								name: item.seoTitle,
																								list: 'Category List', // the type of list for the current product impression )e.g. Category List, Search Results etc.)
																								position: i + 1 + mainItems.length, // the position of the product within the list
																							},
																							'Category List',
																							i + mainItems.length,
																							navigate(`/product/${item.slug}`)
																						)
																					}}
																				>
																					<Continue>View Product</Continue>
																				</SubItemDetails>
																			</SubItemBtns>
																		</SubItemsDetails>
																	</SubItemsInnerContainer>
																</SubItemsContainer>
															)
														}
												  })
												: null}
										</SubItemContainer>
									) : (
										''
									)}
								</ProductsInnerContainer>
							) : (
								<SubItemContainer>
									{products !== null && products.items.length > 0
										? overrideItems.map((item, i) => {
												if (!isEmpty(item)) {
													const title = item.seoTitle
													const titleSplit = title.split(' ')
													const productBrand = titleSplit.find(
														(ttl) => ttl === 'Dolphin' || ttl === 'Mineral' || ttl === 'Ozone'
													)
													const pBrand =
														productBrand === 'Dolphin'
															? productBrand
															: productBrand === 'Mineral'
															? 'Mineral Swim'
															: productBrand === 'Ozone'
															? 'Ozone Swim'
															: ''

													const itemName = item.seoTitle.split(pBrand).slice(1)

													const filterImg = (img: { node: ProductImage }[]) => {
														return img.filter((x) => {
															return x.node.slug === item.slug ? x : ''
														})
													}

													const filteredByslug = filterImg(filteredImages)
													return (
														<SubItemsContainer key={i}>
															<SubItemsInnerContainer className={otherItems.length > 1 ? '' : 'notAuto'}>
																<SubItemsDetails className={otherItems.length > 1 ? '' : 'notAuto'}>
																	<SubItemImageContainer
																		onClick={() => {
																			onProductClick(
																				{
																					...item,
																					name: item.seoTitle,
																					list: 'Category List', // the type of list for the current product impression )e.g. Category List, Search Results etc.)
																					position: i + 1 + mainItems.length, // the position of the product within the list
																				},
																				'Category List',
																				i + mainItems.length,
																				navigate(`/product/${item.slug}`)
																			)
																		}}
																	>
																		<ItemImage>
																			<source
																				srcSet={filteredByslug[0].node.images[0].fluid.srcWebp}
																				type="image/webp"
																			/>
																			<source
																				srcSet={`${
																					filteredByslug[0].node.images[0].fluid.src.split('?')[0]
																				}?fm=png&fl=png8&fit=pad&w=600&h=400`}
																			/>
																			<img
																				src={filteredByslug[0].node.images[0].fluid.src}
																				alt={filteredByslug[0].node.images[0].description}
																			/>
																		</ItemImage>
																	</SubItemImageContainer>
																	<DetailsContainer className={otherItems.length > 1 ? '' : 'notAuto'}>
																		<ProductName>
																			<span>{pBrand}</span>
																			{itemName}
																		</ProductName>
																		{item.featuresCollection.items.map((feature, i) => {
																			return <Feature key={`${feature.title}${i}`}>{feature.title}</Feature>
																		})}
																	</DetailsContainer>
																	<SubItemBtns className={otherItems.length > 1 ? '' : 'notAuto'}>
																		{item.active === null || item.active === true ? (
																			prodSeries != 'Wave' ? (
																				// <SubItemAddToCartContainer>
																				// 	<Price>
																				// 		{item.price.toLocaleString('th-TH', {
																				// 			style: 'currency',
																				// 			currency: 'SGD',
																				// 		})}
																				// 	</Price>
																				// 	<AddToCart items={item} index={i} />
																				// </SubItemAddToCartContainer>
																				<SubEnquire
																					to={`/contact-us/?subject=${encodeURIComponent(
																						item.seoTitle.toLowerCase()
																					)}#contact-form`}
																				>
																					<Enquire>Enquire now</Enquire>
																				</SubEnquire>
																			) : (
																				<SubTrialRequest
																					to={`/contact-us/?subject=commercial+trial+${item.model}#contact-form`}
																				>
																					<Request>Request a Trial</Request>
																				</SubTrialRequest>
																			)
																		) : prodSeries != 'Wave' ? (
																			<SubEnquire
																				to={`/contact-us/?subject=${encodeURIComponent(
																					item.seoTitle.toLowerCase()
																				)}#contact-form`}
																			>
																				<Enquire>Enquire now</Enquire>
																			</SubEnquire>
																		) : (
																			<SubEnquire
																				to={`/contact-us/?subject=commercial+trial+${encodeURIComponent(
																					item.model.toLowerCase()
																				)}#contact-form`}
																			>
																				<Enquire>Request a Trial</Enquire>
																			</SubEnquire>
																		)}
																		<SubItemDetails
																			onClick={() => {
																				onProductClick(
																					{
																						...item,
																						name: item.seoTitle,
																						list: 'Category List', // the type of list for the current product impression )e.g. Category List, Search Results etc.)
																						position: i + 1 + mainItems.length, // the position of the product within the list
																					},
																					'Category List',
																					i + mainItems.length,
																					navigate(`/product/${item.slug}`)
																				)
																			}}
																		>
																			<Continue>View Product</Continue>
																		</SubItemDetails>
																	</SubItemBtns>
																</SubItemsDetails>
															</SubItemsInnerContainer>
														</SubItemsContainer>
													)
												}
										  })
										: null}
								</SubItemContainer>
							)
						) : (
							<></>
						)}
					</Main>
				)}
			</ProductsContainer>
		</MainSection>
	)
}

const APOLLO_PRODUCT_LINE_QUERY = gql`
	query prodLineQuery($series: String!) {
		productCollection(
			locale: "th-TH"
			where: { series: $series, countryAvailability_contains_some: ["TH"] }
			order: [popularity_DESC]
		) {
			items {
				seoTitle
				seoDescription
				slug
				sku
				price
				model
				brand
				active
				categories {
					title: seoTitle
					slug
				}
				shippingClass
				imagesCollection(limit: 1) {
					items {
						url
						description
					}
				}
				featuresCollection(limit: 3) {
					items {
						title
					}
				}
			}
		}
	}
`

Products.propTypes = {
	productImage: PropTypes.any,
	prodSeries: PropTypes.string,
	locale: PropTypes.string,
}

export default Products
